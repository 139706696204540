/* --------------------------------
   header
-------------------------------- */

$column-gutter: 30px;

.top-bar--light
{
	color: $white;

	&.is-sticky { background-color: #202831; }

	&.is-expanded
	{
		.top-bar__collapse { background-color: #202831; }

		.top-bar__navigation
		{
			border-bottom: 1px solid rgba(#f2f2f2, 0.25);

			li { border-top: 1px solid rgba(#f2f2f2, 0.25); }
		}
	}

	.top-bar__navigation
	{
		a
		{
			&:after { background-color: currentColor; }

			@include media-breakpoint-up(xl)
			{
				&.active { color: inherit; }
			}
		}
	}

	.top-bar__auth-btns
	{
		a
		{
			&:after { background-color: currentColor; }
		}
	}
}

.top-bar--dark
{
	color: #333;

	&.is-sticky
	{
		background-color: $white;
		box-shadow: 0px 1px 5px 0px rgba(#242424, 0.12);
	}

	&.is-expanded
	{
		.top-bar__collapse { background-color: $white; }

		.top-bar__navigation
		{
			border-bottom: 1px solid #f2f2f2;

			li { border-top: 1px solid #f2f2f2; }
		}
	}

	.top-bar__navigation
	{
		a
		{
			&:after { background-color: lighten(desaturate(adjust-hue($primary-color, 10), 27.90), 1.76); }

			@include media-breakpoint-up(xl)
			{
				&.active { color: #a3a3a3; }
			}
		}
	}

	.top-bar__auth-btns
	{
		a
		{
			&:after { background-color: lighten(desaturate(adjust-hue($primary-color, 10), 27.90), 1.76); }
		}
	}
}

.top-bar
{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	padding: 10px 0;
	font-size: 1.6rem;
	font-weight: 700;
	@include transition(top 0.3s);
	z-index: 5;

	&.in
	{
		-webkit-animation-name: TopBarSlideInDown;
		animation-name: TopBarSlideInDown;
		animation-duration: 300ms;

		@-webkit-keyframes TopBarSlideInDown {
			from {
				-webkit-transform: translate3d(0, -100%, 0);
				transform: translate3d(0, -100%, 0);
				visibility: visible;
			}

			to {
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
		}

		@keyframes TopBarSlideInDown {
			from {
				-webkit-transform: translate3d(0, -100%, 0);
				transform: translate3d(0, -100%, 0);
				visibility: visible;
			}

			to {
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
		}
	}

	&.out
	{
		-webkit-animation-name: TopBarSlideOutUp;
		animation-name: TopBarSlideOutUp;
		animation-duration: 200ms;

		@-webkit-keyframes TopBarSlideOutUp {
			from {
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}

			to {
				visibility: hidden;
				-webkit-transform: translate3d(0, -100%, 0);
				transform: translate3d(0, -100%, 0);
			}
		}

		@keyframes TopBarSlideOutUp {
			from {
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}

			to {
				visibility: hidden;
				-webkit-transform: translate3d(0, -100%, 0);
				transform: translate3d(0, -100%, 0);
			}
		}
	}

	&.is-sticky
	{
		position: fixed;
		top: 0;
		animation-fill-mode: both;
	}

	&.is-expanded
	{
		.top-bar__collapse
		{
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			height: 100vh;
			border-top: 80px solid transparent;
			border-bottom: 40px solid transparent;
			overflow-y: auto;
		}

		.top-bar__navigation
		{
			margin-bottom: 30px;

			&:last-child { margin-bottom: 0; }

			li
			{
				padding-left: 15px;
				padding-right: 15px;

				&.has-submenu:before { margin-top: 20px; }
			}

			a
			{
				&:not(.custom-btn)
				{
					display: block;
					padding-top: 17px;
					padding-bottom: 17px;
				}
			}
		}

		.top-bar__action { padding: 0 15px; }
	}

	a
	{
		&:not(.custom-btn)
		{
			color: inherit;
			text-decoration: none;
		}
	}

	&__inner
	{
		margin-left: auto;
		margin-right: auto;
		max-width: 1480px + $column-gutter;
	}

	&__logo
	{
		position: relative;
		z-index: 6;

		img {  }
	}

	&__navigation-toggler
	{
		position: absolute;
		top: 10px;
		right: 15px;
		padding: 22px 10px;
		z-index: 6;

		span
		{
			position: relative;
			display: block;
			height: 2px;
			width: 27px;

			&:before,
			&:after
			{
				content: "";
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
			}

			&:before { top: -8px; }
			&:after { top: 8px; }
		}

		span,
		span:before,
		span:after
		{
			background-color: currentColor;
			@include transition(all 400ms ease-in-out);
		}

		&.is-active
		{
			span
			{
				background-color: transparent !important;

				&:before,
				&:after
				{
					top: 0;
					transform-origin: 50% 50%;
				}

				&:before { transform: rotate(225deg); }
				&:after { transform: rotate(-225deg); }
			}
		}
	}

	&__collapse
	{
		height: 0;
		overflow-y: hidden;
	}

	&__navigation
	{
		position: relative;
		text-align: left;

		ul
		{
			line-height: 0;
			font-size: 0;
			letter-spacing: -1px;

			&:before,
			&:after
			{
				content: "";
				display: table;
				clear: both;
			}
		}

		li
		{
			position: relative;
			font-size: 1.6rem;
			line-height: 1;
			letter-spacing: 0;
			white-space: normal;

			&:first-child
			{
				margin-top: 0 !important;
				margin-left: 0 !important;
			}

			&.has-submenu
			{
				&:before
				{
					content: "";
					float: right;
					width: 6px;
					height: 6px;
					border-bottom: 2px solid;
					border-right: 2px solid;
					border-color: currentColor;
					margin-left: 10px;
					margin-top: 4px;
					transform: rotate(45deg);
					transform-origin: center;
					@include transition(
						border-color 0.3s ease-in-out
					);
				}
			}
		}

		a
		{
			&:not(.custom-btn)
			{
				position: relative;
				display: inline-block;
				padding: 0;
				line-height: inherit;
				font-size: inherit;
				font-family: inherit;
				@include transition(
					background-color 0.3s ease-in-out,
					border-color     0.3s ease-in-out,
					color            0.3s ease-in-out
				);

				&:before,
				&:after
				{
					pointer-events: none;
				}
			}

			&.active { color: $primary-color; }
		}

		.submenu
		{
			display: none;

			li { }

			a { }

			.submenu { }
		}
	}

	&__action
	{
		margin-left: auto;
	}

	&__choose-lang
	{
		position: relative;
		display: inline-block;
		vertical-align: middle;

		.current-lang
		{
			display: table;
			min-width: 70px;
			line-height: 1;
			cursor: pointer;

			>*
			{
				display: table-cell;
				vertical-align: middle;
			}

			span
			{
				padding-left: 10px;

				&:after
				{
					content: "";
					float: right;
					width: 6px;
					height: 6px;
					border-bottom: 2px solid;
					border-right: 2px solid;
					border-color: currentColor;
					margin-left: 8px;
					margin-top: 4px;
					transform: rotate(45deg);
					transform-origin: center;
					@include transition(
						border-color 0.3s ease-in-out
					);
				}
			}
		}

		.list-wrap
		{
			display: none;

			ul
			{
				margin-top: 15px;
				padding-top: 40px;
				padding-bottom: 40px;
				line-height: 1;
				background-color: #2f3c46;
			}

			li
			{
				position: relative;
				margin-top: 15px;
				margin-left: 15px;
				margin-right: 15px;
				line-height: 1.2;
				font-size: 1.4rem;
				font-weight: 400;
				color: $white;
				cursor: pointer;

				&:first-child { margin-top: 0; }

				span
				{
					position: relative;
					display: inline-block;
					vertical-align: top;

					&:after
					{
						content: "";
						display: block;
						position: absolute;
						top: 100%;
						left: 50%;
						width: 0;
						height: 2px;
						margin-top: 3px;
						opacity: 0;
						visibility: hidden;
						@include transition(
							left       0.3s ease-in-out,
							width      0.3s ease-in-out,
							opacity    0.3s ease-in-out,
							visibility 0.3s ease-in-out
						);
					}
				}

				&.is-active
				{
					color: #8d9296;
					cursor: default;

					span:after
					{
						left: 0;
						width: 100%;
						opacity: 1;
						visibility: visible;
						background-color: #2158a6;
					}
				}
			}
		}

		img
		{
			display: inline-block;
			width: 25px;
			height: 25px;
		}
	}

	&__auth-btns
	{
		margin-top: 20px;
		line-height: 1;

		&:first-child { margin-top: 0; }

		a
		{
			position: relative;
			display: inline-block;
			vertical-align: middle;
			margin-left: 20px;

			&:first-child { margin-left: 0; }

			&:not(.custom-btn)
			{
				&:after
				{
					content: "";
					display: block;
					position: absolute;
					top: 100%;
					left: 50%;
					width: 0;
					height: 2px;
					margin-top: 9px;
					opacity: 0;
					visibility: hidden;
					pointer-events: none;
					@include transition(
						left       0.3s ease-in-out,
						width      0.3s ease-in-out,
						opacity    0.3s ease-in-out,
						visibility 0.3s ease-in-out
					);
				}

				&:hover
				{
					&:after
					{
						left: 0;
						width: 100%;
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}

	&__side-menu-button
	{
		display: none;
		vertical-align: middle;
		margin-left: 20px;
		padding: 5px 0;
		cursor: pointer;

		.line
		{
			display: block;
			width: 27px;
			border-top: 2px solid currentColor;
			margin-top: 5px;
			margin-left: auto;
			@include transition( width 0.3s ease-in-out );

			&:first-child { margin-top: 0; }

			&:last-child { width: 18px; }
		}

		&:hover,
		&:focus
		{
			.line
			{
				&:last-child { width: 27px; }
			}
		}
	}
}

@include media-breakpoint-down(md)
{
	
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	.top-bar
	{
		top: 15px;
		padding: 18px 0;

		&__navigation-toggler { display: none; }

		&__navigation
		{
			margin-left: 40px;

			%activeLink
			{
				&:after
				{
					left: 0;
					width: 100%;
					opacity: 1;
					visibility: visible;
				}
			}

			li
			{
				display: inline-block;
				vertical-align: middle;
				margin-left: 15px;

				&.has-submenu
				{
					&:hover
					{
						> .submenu
						{
							margin-top: 20px;
							opacity: 1;
							visibility: visible;
						}
					}
				}

				&:hover
				{
					>a { @extend %activeLink; }
				}
			}

			a
			{
				&:after
				{
					content: "";
					display: block;
					position: absolute;
					top: 100%;
					left: 50%;
					width: 0;
					height: 2px;
					margin-top: 9px;
					opacity: 0;
					visibility: hidden;
					@include transition(
						left       0.3s ease-in-out,
						width      0.3s ease-in-out,
						opacity    0.3s ease-in-out,
						visibility 0.3s ease-in-out
					);
				}

				&.active { @extend %activeLink; }
			}

			.submenu
			{
				$submenuWidth: 230px;
				$submenuSpace: 55px;

				display: block;
				border-top-width: 0;
				position: absolute;
				top: 100%;
				right: 50%;
				min-width: $submenuWidth;
				margin-top: 40px;
				margin-right: -($submenuWidth/2);
				padding: 35px 0 40px;
				background-color: #2f3c46;
				color: $white;
				visibility: hidden;
				opacity: 0;
				z-index: 3;
				@include transition(
					opacity 0.2s ease-in-out,
					margin-top 0.3s ease-in-out,
					visibility 0.2s ease-in-out
				);

				&:before
				{
					content: "";
					display: block;
					margin-top: -$submenuSpace;
					height: $submenuSpace;
				}

				li
				{
					display: list-item;
					margin-top: 5px;
					margin-left: 0;
					padding: 5px 50px;
					font-size: 1.4rem;
					font-weight: 400;
					line-height: 1.4;

					&.active,
					&:hover
					{
						>a { color: #8d9296; }
					}

					&.has-submenu
					{
						&:hover
						{
							&:before { border-color: #8d9296; }

							.submenu { margin-top: 0; }
						}
					}
				}

				a
				{
					&:after
					{
						margin-top: 0;
						background-color: #0383c3 !important;
					}

					&:hover,
					&:focus
					{
						color: #8d9296;
					}
				}

				.submenu
				{
					top: 0;
					right: 100%;
					margin-right: 0;
					background-color: #27343d;

					&:before { content: none; }
				}
			}
		}

		&__collapse
		{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			flex-grow: 1;
			flex-basis: 0;
			height: auto;
			overflow: visible;
		}

		&__choose-lang
		{
			.list-wrap
			{
				position: absolute;
				top: 100%;
				left: 50%;
				width: 160px;
				margin-left: - 160/2 + px;

				li
				{
					margin-left: 45px;
					margin-right: 45px;
				}
			}
		}

		&__auth-btns
		{
			margin-top: 0;
			margin-left: 25px;
		}

		&__side-menu-button { display: inline-block; }
	}
}

@include min-screen(1400px)
{
	.top-bar
	{
		&__navigation
		{
			margin-left: 100px;

			li { margin-left: 25px; }
		}

		&__auth-btns
		{
			margin-left: 50px;

			a { margin-left: 30px; }
		}

		&__side-menu-button { margin-left: 35px; }
	}
}